



















import Vue from "vue"
import Component from "vue-class-component"
import { Action, Mutation, State } from "vuex-class"
import { Watch } from "vue-property-decorator"

import Page from "custom/Page.vue"
import ContentHeader from "custom/ContentHeader.vue"

import { StoreMutation, StoreAction } from "lib/types/vuex"
import Secured from "lib/security/Secured"
import { session, postResponse } from "api/request"
import { digid } from "api/settings"
import eventBus from "lib/vue/eventBus"
import { ACCESS_GRANTED, ACCESS_RESTORED } from "lib/vue/events"
import { Locale } from "store/types"

import options from "i18n/nl-NL"
import i18n from "lib/i18n/install"

import load from "i18n/load"
import { save as saveLocale } from "lib/i18n/session"
import { get } from "lodash-es"

Vue.use(i18n, options)

@Secured(session)

@Component({
	components: {
		Page,
		ContentHeader
	}
})

export default class Start extends Vue {
	@State locale!: Locale
	@Action login!: StoreAction<any>
	@Mutation("locale") setLocale!: StoreMutation<Locale>

	isSystemBusy = false

	options: any
	translateBeforeLogin = Vue.filter("locale", (code: string) => get(options.texts, code) || `{{ ${code}: undefined }}`)

	@Watch("locale")
	async changeLocale() {
		this.options = await load(this.locale)
		this.translateBeforeLogin = Vue.filter("locale", (code: string) => get(this.options.default.texts, code) || `{{ ${code}: undefined }}`)
	}

	async beforeCreate() {
		if (!process.env.SERVER) {
			// If logon is succesfull but the system is 'slow', notify the user.
			const waitingTimer = setTimeout(() => {
				this.isSystemBusy = true
			}, 30000)

			const samlResponse = new URL(location.href).searchParams.get("SAMLResponse")

			if (samlResponse) {
				// The user is returning from Digid's logout page. Verify the response.
				const response = await postResponse(digid.verifyLogoutUrl!, {samlResponse})
				if (response.ok) {
					await session.logout()
				} else {
					clearTimeout(waitingTimer)
					await this.setLanguage()
					location.href = "./start.html"
				}
			} else {
				eventBus.on(ACCESS_RESTORED, () => {
					clearTimeout(waitingTimer)
					location.href = "./start.html"
				})
				eventBus.on(ACCESS_GRANTED, async () => {
					if (await this.login()) {
						clearTimeout(waitingTimer)
						await this.setLanguage()
						location.href = "./start.html"
					}
				})
			}
		}
	}

	beforeMount() {
		this.setLocale("nl-NL")
	}

	async mounted() {
		await Vue.nextTick()
		await this.changeLocale()
	}

	async setLanguage() {
		const done = await saveLocale(load, this.locale || "nl-NL")
		if (done) {
			this.setLocale(this.locale || "nl-NL")
		}
	}
}
